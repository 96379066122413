import { lazy } from "react";

// project imports
// import Loadable from "components/Loadabl
import { Navigate } from "react-router";
import Loadable from "../components/Loadable";
const MainView = Loadable(lazy(() => import("../views/MainView")));
const UserVerification = Loadable(
  lazy(() => import("../views/UserVerification"))
);
const MainErrorPage = Loadable(
  lazy(() => import("../views/errorPage/MainErrorPage"))
);

// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: <Navigate to="/not-authorised" />,
    },
    {
      path: "ID-verification/:trackingID",
      children: [
        {
          path: "",
          element: <MainView />,
        },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/not-authorised" />,
    },
    {
      path: "not-authorised",
      element: <MainErrorPage />,
    },
  ],
};

export default MainRoutes;
