import "./App.css";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
// project imports
import NavigationScroll from "./components/NavigationScroll";

import { useRoutes } from "react-router";
import MainRoutes from "./routes/MainRoute";
const App = () => {
  const mainRoutes = useRoutes([MainRoutes]);

  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <NavigationScroll>{mainRoutes}</NavigationScroll>
    </StyledEngineProvider>
  );
};

export default App;
